<script setup>
import { defineProps, onMounted, nextTick, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { IonIcon, IonCard, IonRow, IonCol } from "@ionic/vue";
import { checkmarkCircle, chevronDown, chevronUp } from "ionicons/icons";

import { useGeneralStore } from "../../stores/useGeneralStore";
import { useStore } from "vuex";

import AvatarsSlider from "../../components/AvatarsSlider.vue";

const generalStore = useGeneralStore();
const store = useStore();
const users = computed(() => generalStore.userList);

const router = useRouter();

const showList1 = ref(false);
const showList2 = ref(false);

const loggedUser = computed(() => store.getters["user/getUserData"]);

const props = defineProps({
  surveys: Array,
});

function toggleUsersVisibility(S) {
  S.showUsers = !S.showUsers;
}

const navigateToSurvey = (e) => {
  console.log("navigateToSurvey", e);

  generalStore.setSelectedUser(e.id);
  generalStore.setCurrentSurvey(e.survey);
  router.push("/pk-survey/" + e.id);
};

function handleSurveysUsersList() {
  props.surveys.forEach((S) => {
    S.swiperData = [];
    S.showUsers = false;

    S.surveys.forEach((s) => {
      S.swiperData.push({
        url_image: users.value.find((u) => u.id == s.id_utente_valutato)
          .avatar_url,
        id: s.id,
        survey: s,
      });
    });
  });
}

// WATCH props.surveys
watch(
  () => props.surveys,
  () => {
    handleSurveysUsersList();
  }
);

onMounted(async () => {
  if (users.value.length == 0) {
    await generalStore.getUsers();
  }

  handleSurveysUsersList();

  showList1.value = true;
  setTimeout(async () => {
    await nextTick();
    showList2.value = true;
  }, 5);
});
</script>

<template>
  <transition-group name="block-slide-in">
    <template v-for="(survey, i) in surveys" :key="i">
      <ion-card
        v-if="showList1 && showList2"
        :style="{ '--i': i }"
        class="my-4 px-4 pb-4"
      >
        <div
          class="w-full flex flex-column justify-content-start align-items-start"
        >
          <div
            class="w-full flex justify-content-between align-items-center py-2"
          >
            <h4 class="font-bold">{{ survey.t_nome["it-IT"] }}</h4>
            <ion-icon
              v-if="survey.completato"
              :icon="checkmarkCircle"
              size="large"
            ></ion-icon>
          </div>
          <span>
            Inizio:
            <span class="font-bold">{{
              new Date(survey.inizio).toLocaleDateString()
            }}</span>
          </span>
          <span v-if="survey.fine">
            Fine:
            <span class="font-bold">{{
              new Date(survey.fine).toLocaleDateString()
            }}</span>
          </span>

          <div class="w-full flex justify-content-end align-items-center pt-4">
            <div
              v-if="
                survey.swiperData.length == 1 &&
                survey.swiperData[0].survey.id_utente_valutato == loggedUser.id
              "
            >
              <button
                @click="navigateToSurvey(survey.swiperData[0])"
                class="p-sm button-primary"
              >
                Rispondi
              </button>
            </div>

            <button
              v-else
              @click="toggleUsersVisibility(survey)"
              class="p-sm mr-2 text-button text-color-light flex align-items-center"
            >
              <small>Utenti</small>
              <ion-icon
                :icon="survey.showUsers ? chevronUp : chevronDown"
                size="small"
                class="ml-1"
              ></ion-icon>
            </button>
          </div>
        </div>

        <div v-if="survey.showUsers">
          <AvatarsSlider
            :awardsList="survey.swiperData"
            @clickedCurrentSlide="navigateToSurvey"
          />
        </div>
      </ion-card>
    </template>
  </transition-group>
</template>
